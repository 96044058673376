import { RouteConfig } from 'vue-router'
export const salesRoutes: Array<RouteConfig> = [
  // SALES
  { path: '/sales', name: 'Sales', meta: { title: 'Sales', requiresAuth: true }, component: () => import('../../views/accounts/sales/sales.vue') },
  { path: '/invoice/sales-invoice-riyadh/:id', name: 'Sale-Invoice-Aramco-Riyadh', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-riyadh.vue') },
  { path: '/invoice/sales-invoice-yumbu/:id', name: 'Sale-Invoice-Aramco-Yumbu', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-yumbu.vue') },
  { path: '/invoice/sales-invoice-juaymah/:id', name: 'Sale-Invoice-Aramco-Juyamah', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-juaymah.vue') },
  { path: '/invoice/sales-invoice-tanajeeb/:id', name: 'Sale-Invoice-Tanajeeb', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-tanajeeb.vue') },
  { path: '/invoice/sales-invoice-yasref/:id', name: 'Sale-Invoice-Yasref', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-yasref.vue') },
  { path: '/invoice/sales-invoice-yanbu/:id', name: 'Sale-Invoice-Yanbu', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-yanbu.vue') },
  { path: '/invoice/sales-invoice-grainsailos/:id', name: 'Sale-Invoice-GrainSailos', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-grain-sailos.vue') },
  { path: '/invoice/sales-invoice-marafiq/:id', name: 'Sale-Invoice-Marafiq', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-marafiq.vue') },
  { path: '/invoice/sales-invoice-jeddah/:id', name: 'Sale-Invoice-Jeddah', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-jeddah.vue') },
  { path: '/invoice/sales-invoice-kjo/:id', name: 'Sale-Invoice-KJO', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-kjo.vue') },
  { path: '/invoice/sales-invoice-grain-sailos/:id', name: 'Sale-Invoice-Grain-Sailos', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-grain-sailos.vue') },
  { path: '/invoice/sales-invoice-bahria/:id', name: 'Sale-Invoice-Bahria', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-bahria.vue') },
  { path: '/invoice/sales-invoice-bahria-new/:id', name: 'Sale-Invoice-Bahria-New', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-bahria-new.vue') },
  { path: '/invoice/sales-invoice-milling/:id', name: 'Sale-Invoice-Milling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-milling.vue') },
  { path: '/invoice/sales-invoice-aramco-arabic/:id', name: 'Sale-Invoice-Aramco-Arabic', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-arabic.vue') },
  { path: '/invoice/sales-invoice-saptco/:id', name: 'Sale-Invoice-Saptco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-saptco.vue') },
  { path: '/invoice/sales-invoice-philip/:id', name: 'Sale-Invoice-Philip', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-philip.vue') },
  { path: '/invoice/sales-invoice-air-fueling-makkah/:id', name: 'Sale-Invoice-Air-Fueling-Makkah', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-air-fueling-makkah.vue') },
  { path: '/invoice/sales-invoice-air-fueling-aseer/:id', name: 'Sale-Invoice-Air-Fueling-Aseer', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-air-fueling-aseer.vue') },
  { path: '/invoice/sales-invoice-air-fueling-riyadh/:id', name: 'Sale-Invoice-Air-Fueling-Riyadh', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-air-fueling-riyadh.vue') },
  { path: '/invoice/sales-invoice-air-fueling-shirqiya/:id', name: 'Sale-Invoice-Air-Fueling-Shirqiya', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-air-fueling-shirqiya.vue') },
  { path: '/invoice/sales-invoice-warning/:id', name: 'Sale-Invoice-Warning', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-warning.vue') },
  { path: '/invoice/sales-invoice-tameed/:id', name: 'Sale-Invoice-Tameed', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-tameed.vue') },
  { path: '/invoice/sales-invoice-tameed-mod/:id', name: 'Sale-Invoice-Tameed-MOD', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-tameed-mod.vue') },
  { path: '/invoice/sales-invoice-aramco-gmc/:id', name: 'Sale-Invoice-GMC', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-gmc.vue') },
  { path: '/invoice/sales-invoice-aramco-mastoura/:id', name: 'Sale-Invoice-Mastoura', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-mastoura.vue') },
  { path: '/invoice/sales-invoice-authentix/:id', name: 'Sale-Invoice-Authentix', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-authentix.vue') },
  { path: '/invoice/sales-invoice-alarji/:id', name: 'Sale-Invoice-Alarji', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-alarji.vue') },
  { path: '/invoice/sales-invoice-HVAC-Maintenance-crdd/:id', name: 'HVAC-Maintenance-crdd', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-hvac-maintenance.vue') },
  { path: '/invoice/sales-invoice-tameed-turkistani/:id', name: 'Tameed-Turkistani', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-tameed-turkistani.vue') },
  { path: '/invoice/sales-invoice-sadeem-business/:id', name: 'Sadeem-Business', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-sadeem-business.vue') },
  { path: '/invoice/sales-invoice-sceco/:id', name: 'SCECO', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-sceco.vue') },
  { path: '/invoice/sales-invoice-bahria-new-2023/:id', name: 'Sale-Invoice-Bahria-New-2023', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-bahria-new-2023.vue') },
  { path: '/invoice/sales-invoice-replacement-of-switching-devices-kfia/:id', name: 'Sale-Invoice-Aramco-Replacement-of-Switching-Devices-KFIA', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-replacement-of-switching-devices-kfia.vue') },
  { path: '/invoice/sales-invoice-fuel-transfer-cart-kfaa/:id', name: 'Sale-Invoice-Fuel-Transfer-Cart-KFAA', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-fuel-transfer-cart-kfaa.vue') },

  // refactor path here
  { path: '/accounts/sales/invoice/generate-sale-invoice', name: 'Generate-Sale-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/generate-invoice.vue') },
  // refactor here
  { path: '/accounts/sales/invoice/generate-sale-invoice-credit-note', name: 'Generate-Sale-Invoice-Credit-Note', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/generate-invoice-credit.vue') },
  { path: '/invoice/sales-invoice-yumbu-credit/:id', name: 'Sale-Invoice-Yumbu-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-yumbu-credit.vue') },
  { path: '/invoice/sales-invoice-riyadh-credit/:id', name: 'Sale-Invoice-Riyadh-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-riyadh-credit.vue') },
  { path: '/invoice/sales-invoice-jeddah-credit/:id', name: 'Sale-Invoice-Jeddah-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-jeddah-credit.vue') },
  { path: '/invoice/sales-invoice-tanajeeb-credit/:id', name: 'Sale-Invoice-Tanajeeb-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-tanajeeb-credit.vue') },
  { path: '/invoice/sales-invoice-milling-credit/:id', name: 'Sale-Invoice-Milling-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-milling-credit.vue') },
  { path: '/invoice/sales-invoice-aramco-mastoura-credit/:id', name: 'Sale-Invoice-Mastoura-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-mastoura-credit.vue') },
  { path: '/invoice/sales-invoice-aramco-juaymah-credit/:id', name: 'Sale-Invoice-Juaymah-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-aramco-juaymah-credit.vue') },
  { path: '/invoice/sales-invoice-bahria-new-credit/:id', name: 'Sale-Invoice-Bahria-New-Credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-invoice-bahria-new-credit.vue') },

  //refactor here
  { path: '/accounts/sales/invoice/generate-sale-invoice-general-credit-note', name: 'Generate-Sale-Invoice-General-Credit-Note', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/generate-credit-general.vue') },
  { path: '/invoice/sales-credit-jeddah-general/:id', name: 'Sale-Invoice-Jeddah-General', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/invoice/sales-credit-jeddah-general.vue') },
  //customer sale invoice 
  { path: '/accounts/sales/customer-invoice/generate-customer-sale-invoice', name: 'Generate-Customer-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/customer-invoice/generate-customer-sale-invoice.vue') },
  { path: '/accounts/sales/customer-invoice/:id', name: 'Customer-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/customer-invoice/customer-invoice.vue') },
  //cash sale invoice 
  { path: '/accounts/sales/cash-invoice/generate-cash-sale-invoice', name: 'Generate-ash-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/cash-invoice/generate-cash-sale-invoice.vue') },
  { path: '/accounts/sales/cash-invoice/:id', name: 'Cash-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/cash-invoice/cash-invoice.vue') },
  //internal sale invoice
  { path: '/accounts/sales/internal-invoice/generate-internal-invoice.vue', name: 'Generate-Internal-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/internal/generate-internal-invoice.vue') },
  { path: '/accounts/sales/internal-invoice/:id', name: 'Internal-Invoice', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/accounts/sales/internal/internal-invoice.vue') },


]
