
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSaleReports from "@/components/sidebar-sale-reports.vue";
import ReportService from "@/services/reports-service";
import DashboardPDFmake from "@/components/dashboard-pdfmake.vue";

@Component({
  components: {
    Header,
    SidebarSaleReports,
    DashboardPDFmake,
  },
})
export default class Home extends Vue {
  chartShow = false;
  mouseover() {
    if (!this.chartShow) {
      this.chartShow = true;
    }
  }
  mouseleave() {
    this.chartShow = false;
  }

  projectSaleChartOption: any = {};
  projectPerChartOption: any = {};
  monthSaleChartOption: any = {};
  vatChartOption: any = {};
  total_sales = 0;
  total_vat = 0;
  highest_sales = 0;
  total_invoices = 0;

  count: number = 0;
  counter(minimum: number, maximum: number) {
    for (let count = minimum; count <= maximum; count += 999) {
      setTimeout(() => {
        this.count = count;
      }, 5000);
    }
  }
  mounted() {
    this.retrieve();
    this.counter(0, 75000);
  }
  onEnter() {
    console.log("enter");
  }

  getMonthName(month: any) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString("default", { month: "long" });
    return monthName;
  }
  projectSaleSeries: any = [];
  projectSaleChartOptions: any = {};

  projectPerSaleSeries: any = [];
  projectPerSaleChartOptions: any = {};

  monthlySaleSeries: any = [];
  monthlySaleChartOptions: any = {};

  monthlyVatSeries: any = [];
  monthlyVatChartOptions: any = {};
  retrieve() {
    ReportService.getstatictics()
      .then((response) => {
        let master_data = response.data;
        this.total_sales =
          master_data["total_sales"].net_amount__sum.toLocaleString();
        this.total_vat =
          master_data["total_vat"].vat_amount__sum.toLocaleString();
        this.highest_sales =
          master_data["highest_sales"].net_amount__max.toLocaleString();
        this.total_invoices = master_data["total_invoices"].toLocaleString();
        let projectsales = master_data["project_wise_sum"];
        let monthsales = master_data["month_wise_sum"];
        let vatsales = master_data["vat_wise_sum"];

        monthsales.forEach((x: any) => {
          x.month = this.getMonthName(x.month);
        });
        let month = monthsales.map((x: any) => x.month);
        let data_monthly = monthsales.map((x: any) => x.sum);

        vatsales.forEach((x: any) => {
          x.month = this.getMonthName(x.month);
        });
        let month_vat = vatsales.map((x: any) => x.month);
        let data_monthly_vat = vatsales.map((x: any) => x.sum);

        projectsales.forEach((x: any) => { x.name })
        let projectPerSale = projectsales.map((x: any) => x.y)
        let projectName = projectsales.map((x: any) => x.name)
        this.projectSale(projectPerSale, projectName)
        this.projectPerSale(projectPerSale, projectName)
        this.monthlySale(data_monthly, month)
        this.monthlyVat(data_monthly_vat, month_vat)
      })
      .catch((e) => {
        console.log(e);
      });
  }


  monthlySale(data: any, category: any) {
    this.monthlySaleSeries = [{
      name: 'Inflation',
      data: data
    }],
      this.monthlySaleChartOptions = {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: category,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: any) {
              return val + "%";
            }
          }

        },
        // title: {
        //   text: 'Monthly Inflation in Argentina, 2002',
        //   floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      }
  }

  monthlyVat(data: any, category: any) {
    this.monthlyVatSeries = [{
      name: 'Inflation',
      data: data
    }],
      this.monthlyVatChartOptions = {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: category,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: any) {
              return val + "%";
            }
          }

        },
        // title: {
        //   text: 'Monthly Inflation in Argentina, 2002',
        //   floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      }
  }

  projectSale(data: any, category: any) {
    this.projectSaleSeries = [{
      name: 'Inflation',
      data: data
    }],
      this.projectSaleChartOptions = {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: category,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val: any) {
              return val + "%";
            }
          }

        },
        // title: {
        //   text: 'Monthly Inflation in Argentina, 2002',
        //   floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      }
  }

  projectPerSale(data: any, label: any) {
    this.projectPerSaleSeries = data
    this.projectPerSaleChartOptions = {
      chart: {
        type: 'donut',
      },
      labels: label,
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        formatter: function (val: any, opts: any) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      // title: {
      //   text: 'Gradient Donut with custom Start-angle'
      // },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

}
