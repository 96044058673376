import { RouteConfig } from 'vue-router'
export const errorRoutes: Array<RouteConfig> = [

  {
    path: '*',
    component: () => import('../../views/error/404.vue')
  },
  {
    path: '*',
    component: () => import('../../views/error/403.vue')
  },
]
