
import router from "@/router";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { nav_data, settings_menue, user_menue } from "../assets/data";
import { recentActivites } from "../utils/RecentActivites";
import { bookmark } from "../utils/BookMark";
import Notification from "./notification.vue"; // @ is an alias to /src
import HrmService from "@/services/hrm-service";
import UserManagementService from "@/services/user-management";
import AuthService from "@/services/auth-service"
@Component({
  components: {
    Notification,
  },
})
export default class Header extends Vue {
  @Prop({ default: false }) triggerNotification!: Boolean;
  constructor() {
    super();
    this.savedBookMark = bookmark.getBookMarkList();
  }
  parseRoutes: any = null;
  public userData: any = {}

  checkParseRoute(parse_word: string, level: number): boolean {
    let return_val = false;
    if (this.parseRoutes !== null) {
      this.parseRoutes.some((items: string) => {
        if (items === null) return;
        let parse_split = items.split("/");

        if (parse_split[level] === parse_word) {
          return_val = true;
          return true;
        } else {
          return_val = false;
          return false;
        }

      });
    }

    return return_val;
  }
  checkRoutes(url: string, level: number): boolean {

    let level1_route = url.split("/");
    let level1 = level1_route[level];
    let status = this.checkParseRoute(level1, level);
    return status;

  }
  logout(item: any) {
    if (item.name === 'Logout') {
      localStorage.removeItem("route");
      localStorage.removeItem('user');
      UserManagementService.logout()
        .then((res) => {
          localStorage.removeItem("jwt");
        })
    }
  }

  showNotifications = false;
  showDismissibleAlert = true;
  nav_data = nav_data;
  settings_menue = settings_menue;
  user_menue = user_menue;
  recent_activites_menue: any = [];
  allBookMark: any = [];
  savedBookMark: any = [];

  main_drop_url = "";
  sub_drop_url = "";
  sub_sub_drop_url = "";
  sub_sub_sub_drop_url = "";
  bookmarkSavedInputValue = "";
  bookmarkAllInputValue = "";

  settings_drop = false;
  user_menue_drop = false;
  recent_activities_drop = false;
  bookmark_main_drop = false;
  bookmark_inner_drop = false;

  handlePageClick(event: MouseEvent) {
    this.checkNotifications();
    const target = event.target as Element;
    if (
      (this.main_drop_url != "" ||
        this.settings_drop == true ||
        this.user_menue_drop == true ||
        this.recent_activities_drop == true ||
        this.bookmark_main_drop == true) &&
      !target.closest(".drop") &&
      !target.closest(".settings") &&
      !target.closest(".bookmark-main") &&
      !target.closest(".user")
    ) {
      this.main_drop_url = "";
      this.closeAllRightDrops();
      window.removeEventListener("click", this.handlePageClick);
    }
  }

  closeAllRightDrops() {
    this.settings_drop = false;
    this.user_menue_drop = false;
    this.recent_activities_drop = false;
    this.bookmark_main_drop = false;
  }
  mainDrop(url: any) {
    if (url === this.main_drop_url) {
      this.main_drop_url = "";
      return;
    }
    this.main_drop_url = url;
    this.sub_drop_url = "";
    this.sub_sub_drop_url = "";
    this.closeAllRightDrops();
    window.addEventListener("click", this.handlePageClick);
  }
  subDrop(parent_url: any, url: any) {
    this.sub_drop_url = parent_url + url;
  }
  subSubDrop(grand_url: any, parent_url: any, url: any) {
    this.sub_sub_drop_url = grand_url + parent_url + url;
  }
  subSubSubDrop(grand_url: any, parent_url: any, child_url: any, url: any) {
    this.sub_sub_sub_drop_url = grand_url + parent_url + child_url + url;
  }
  recentActivitiesDrop() {
    this.recent_activites_menue = recentActivites.allActivites();
    this.recent_activities_drop = !this.recent_activities_drop;
    this.settings_drop = false;
    this.user_menue_drop = false;
    this.main_drop_url = "";
    this.bookmark_main_drop = false;
    window.addEventListener("click", this.handlePageClick);
  }
  settingsDrop() {
    this.settings_drop = !this.settings_drop;
    this.user_menue_drop = false;
    this.main_drop_url = "";
    this.recent_activities_drop = false;
    this.bookmark_main_drop = false;
    window.addEventListener("click", this.handlePageClick);
  }
  userDrop() {
    this.user_menue_drop = !this.user_menue_drop;
    this.settings_drop = false;
    this.main_drop_url = "";
    this.recent_activities_drop = false;
    this.bookmark_main_drop = false;
    window.addEventListener("click", this.handlePageClick);
  }
  mainBookMarkDrop() {
    this.bookmark_main_drop = !this.bookmark_main_drop;
    this.bookmark_inner_drop = false;
    this.settings_drop = false;
    this.main_drop_url = "";
    this.recent_activities_drop = false;
    window.addEventListener("click", this.handlePageClick);
  }
  //#region Book mark

  filterList(item: string, type: "all" | "saved") {
    if (type == "all") {
      this.allBookMark = bookmark.searchInGenratedList(item);
    } else if (type == "saved") {
      this.savedBookMark = bookmark.searchInGenratedList(item);
    }
  }
  get searchSavedItems() {
    return this.bookmarkSavedInputValue;
  }

  set searchSavedItems(newValue) {
    this.bookmarkSavedInputValue = newValue;
    if (newValue.length == 0) {
      this.savedBookMark = bookmark.getBookMarkList();
      return;
    }
    this.filterList(newValue, "saved");
  }
  get searchAllItems() {
    return this.bookmarkAllInputValue;
  }
  set searchAllItems(newValue) {
    this.bookmarkAllInputValue = newValue;
    this.filterList(newValue, "all");
  }

  addFavourites(item: any) {
    bookmark.createBookMarkList(item);
    this.allBookMark = bookmark.getBookMarkList();
    this.savedBookMark = bookmark.getBookMarkList();
  }
  //#endregion

  haveChild(items: any) {
    return (
      Object.keys(items).includes("child") && !items.hasOwnProperty("disable")
    );
  }

  handleRoutes(url: any, name: string) {
    if (url === recentActivites.lastActivity()) return;
    router.push(url);
    recentActivites.push(url, name);
  }

  navigate(type: string, items: any, name: string) {
    // console.log(items);
    switch (type) {
      case "grand":
        if (this.haveChild(items)) return;
        if (items.hasOwnProperty("disable")) return;
        this.handleRoutes(this.main_drop_url, name); //######## to check how it was implemented in past
        break;
      case "parent":
        if (this.haveChild(items)) return;
        if (items.hasOwnProperty("disable")) return;

        this.handleRoutes(this.sub_drop_url, name);
        break;
      case "child":
        if (this.haveChild(items)) return;
        if (items.hasOwnProperty("disable")) return;

        this.handleRoutes(this.sub_sub_drop_url, name);
        break;
      case "childofchild":
        this.handleRoutes(this.sub_sub_sub_drop_url, name);
        break;
    }
  }

  handleCloseNotifications() {
    this.showNotifications = false;
  }
  notification: [] = [];

  checkNotifications() {
    HrmService.getNotification().then((response: any) => {
      this.notification = JSON.parse(response.data);
    });
  }
  retrieve() {
    AuthService.getauth()
      .then((response) => {
        this.userData = response.data[0];
        const localUser = JSON.stringify(this.userData)
        localStorage.setItem('user', localUser)
      })
      .catch((e) => {
        console.log(e);
      });
  }
  mounted() {
    this.retrieve();
    this.checkNotifications();
    const localRoutes = localStorage.getItem("route");
    if (localRoutes !== null) {
      this.parseRoutes = JSON.parse(localRoutes);
    }
  }
  @Watch("triggerNotification")
  onModalShowChanged(newValue: boolean, oldValue: boolean) {
    this.checkNotifications();
  }
}
