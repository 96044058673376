import { RouteConfig } from 'vue-router'
export const authRoutes: Array<RouteConfig> = [
  //  auth
  { path: '/login', name: 'Login', component: () => import('../../views/auth/login.vue') },
  { path: '/forget', name: 'Forget', component: () => import('../../views/auth/forget.vue') },
  { path: '/api/password-update/*', name: 'Change-Password', component: () => import('../../views/auth/changePassword.vue') },

  { path: '/accounts', name: 'Account', meta: { title: 'Accounts', requiresAuth: true }, component: () => import('../../views/auth/accounts.vue') },
  { path: '/404', name: 'Error-404', component: () => import('../../views/error/404.vue') },
  { path: '/403', name: 'Error-403', component: () => import('../../views/error/403.vue') },

]
