import { RouteConfig } from 'vue-router'
export const invoicesRoutes: Array<RouteConfig> = [
  // Invoices
  { path: '/accounts/purchase/invoices/purchase-voucher-list', name: 'Purchase-Voucher-List', component: () => import('../../views/accounts/purchase/invoices/purchase-vouchers-list.vue') },
  { path: '/accounts/purchase/invoices/purchase-voucher', name: 'Purchase-Voucher', component: () => import('../../views/accounts/purchase/invoices/purchase-voucher.vue') },
  { path: '/accounts/purchase/invoices/cash-purchase-voucher', name: 'Cash-Purchase-Voucher', component: () => import('../../views/accounts/purchase/invoices/cash-purchase-voucher.vue') },
  { path: '/accounts/purchase/invoices/purchase-voucher-cancellation', name: 'Purchase-Voucher-Cancellation', component: () => import('../../views/accounts/purchase/invoices/purchase-voucher-cancellation.vue') },
  { path: '/accounts/purchase/invoices/purchase-voucher-against-orders', name: 'Purchase-Voucher-Against-Orders', component: () => import('../../views/accounts/purchase/invoices/purchase-voucher-againts-orders.vue') },
  { path: '/accounts/purchase/invoices/purchase-voucher-against-receipts', name: 'Purchase-Voucher-Against-Receipts', component: () => import('../../views/accounts/purchase/invoices/purchase-voucher-againts-receipts.vue') },
]
