// import axios from "../common-axios";
import axios from "axios";

class ReportService {
  getAll() {
    return axios.get("/accounts/chart/");
  }

  getchild(id: string) {
    return axios.get(`/accounts/get-child/${id}`);
  }

  getchartchildren() {
    return axios.get(`/accounts/get-children/`);
  }

  get(id: string) {
    return axios.get(`/accounts/chart/${id}`);
  }

  getallsalereports(param: any) {
    return axios.post(`/sales/get-sale-order-filter/`, param)
  }

  getallcreditreports(param: any) {
    return axios.post(`/sales/get-sale-credit-filter/`, param)
  }

  getstatictics() {
    return axios.get(`/sales/get-statistics/`)
  }

  getsalereport(id: string) {
    return axios.get(`/sales/get-sale-order/${id}`)
  }

  getaccountstatement(data: any) {
    return axios.post(`reports/get-accounts-statement/`, data);
  }

  get_transaction_id(id: any) {
    return axios.get(`/finance/get-transaction/${id}`)
  }

  update(id: string, data: any) {
    return axios.put(`/accounts/chart/${id}/`, data);
  }

  delete(id: string) {
    return axios.delete(`/accounts/chart/${id}`);
  }

  deleteAll() {
    return axios.delete(`/accounts/chart/`);
  }

  findByTitle(title: string) {
    return axios.get(`/accounts/chart/?title=${title}`);
  }

  getledger(id: any) {
    return axios.post(`/finance/get-transaction-ledger/`, id)
  }

  gettrailbalance(id: any) {
    return axios.post(`/finance/get-trail-balance/`, id)
  }

  trailbalance() {
    return axios.get(`/finance/trail-balance/`)
  }
  gettrialbalancebyid(id: any) {
    return axios.get(`/finance/account-by-id/`, id)
  }

}


export default new ReportService();