
import router from "@/router";
import hrmService from "@/services/hrm-service";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Notification extends Vue {
  @Prop({ default: "" }) notification!: [];
  @Prop({ type: Function, default: () => {} })
  handleCloseNotifications!: Function;
  // Types
  //attendance
  handleClick(data: any) {
    switch (data.import_type) {
      case "Attendance":
        this.seen(data.id);
        hrmService
          .seenNotification(data.id)
          .then((response) => {
            console.log(response);
            router.push("/hrm/employee/attendance");
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "Uploaded Payroll":
        this.seen(data.id);
        hrmService
          .seenNotification(data.id)
          .then((response) => {
            console.log(response);
            router.push("/hrm/payroll/payroll-list");
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "Genrated Payroll":
        this.seen(data.id);
        hrmService
          .seenNotification(data.id)
          .then((response) => {
            console.log(response);
            router.push("/hrm/payroll/payroll-list");
          })
          .catch((err) => {
            console.log(err);
          });
        break;
    }
  }
  seen(id: string) {}
  handleClose(item: any) {
    hrmService
      .seenNotification(item.id)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // Handle the close event when the close button is clicked
    // console.log("Alert closed:", this.alertItems[index].message);
    // this.alertItems.splice(index, 1); // Remove the closed alert from the list
  }
}
