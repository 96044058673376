import { Vue } from 'vue-property-decorator';

interface MenuNode {
    name: string;
    url: string;
    child?: MenuNode[];
}

export class BookMark {
    // function to combine the parent and child's urls and return name of child
    private generateChildList(nodes: MenuNode[], parentUrl: string): { name: string; url: string; status: boolean }[] {
        const menuList: { name: string; url: string; status: boolean }[] = [];

        nodes.forEach((node) => {
            const { name, url } = node;
            const currentUrl = parentUrl + url;

            if (node.child) {
                menuList.push(...this.generateChildList(node.child, currentUrl));
            } else {
                menuList.push({ name, url: currentUrl, status: false });
            }
        });

        return menuList;
    }
    // genrate the list from navbar data
    public generateList(nodes: MenuNode[]) {
        const menuList: { name: string; url: string; status: boolean }[] = [];

        nodes.forEach((node) => {
            const { name, url } = node;
            const parentUrl = '';

            if (node.child) {
                menuList.push(...this.generateChildList(node.child, parentUrl + url));
            } else {
                menuList.push({ name, url: parentUrl + url, status: false });
            }
        });
        localStorage.setItem('bookmark_list', JSON.stringify(menuList));
    }
    // filter the data from the menue list that is genrated by the upper function's
    public searchInGenratedList(searchString: string): { name: string; url: string; status: boolean }[] {
        const bookmarkList: { name: string; url: string; status: boolean }[] = JSON.parse(localStorage.getItem('bookmark_list') || '[]');;
        const filteredBookmarkList = bookmarkList.filter((bookmark) => {
            return bookmark.name.toLowerCase().includes(searchString.toLowerCase());
        });
        return filteredBookmarkList;
    }

    public createBookMarkList(object?: { name: string; url: string; status: boolean }) {
        let myArray: { name: string; url: string; status: boolean }[] = JSON.parse(localStorage.getItem('bookmark_list') || '[]');

        if (object) {
            const index = myArray.findIndex(obj => obj.name === object.name && obj.url === object.url);

            if (index !== -1) {
                myArray[index].status = !myArray[index].status;
            } else {
                object.status = true;
                myArray.push(object);
            }
        }
        localStorage.setItem('bookmark_list', JSON.stringify(myArray));
    }
    public getBookMarkList(): { name: string; url: string; status: boolean }[] {
        let myArray: { name: string; url: string; status: boolean }[] = JSON.parse(localStorage.getItem('bookmark_list') || '[]');
        return myArray
    }
}

export const bookmark = new BookMark();
