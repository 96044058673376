import { RouteConfig } from 'vue-router'
export const hrmRoutes: Array<RouteConfig> = [
    { path: '/hrm/employee/employee-list', name: 'Employee', meta: { title: 'Employee', requiresAuth: true }, component: () => import('../../views/hrm/employee/employee.vue') },

    { path: '/create-employee', name: 'Create', props: true, meta: { title: 'Create Employee', requiresAuth: true }, component: () => import('../../views/hrm/employee/create.vue') },
    // raw can be use in future
    // { path: '/create-salary', name: 'Create-Salary', props: true, meta: { title: 'Create Salary', requiresAuth: true }, component: () => import('../../views/hrm/salary/create.vue') },
    { path: '/create-attendance', name: 'Create-Attendance', props: true, meta: { title: 'Create Attendance', requiresAuth: true }, component: () => import('../../views/hrm/attendance/create.vue') },
    { path: '/payroll-create', name: 'Payroll-Create', props: true, meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../../views/hrm/payroll/create.vue') },
    { path: '/payroll-slip/:id', name: 'Payroll-Slip', meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../../views/hrm/payroll/payslip.vue') },



    // Here
    { path: '/hrm/employee/salary', name: 'Salary', meta: { title: 'Salary', requiresAuth: true }, component: () => import('../../views/hrm/salary/salary.vue') },
    // { path: '/hrm/employee/attendance', name: 'Attendance', meta: { title: 'Attendance', requiresAuth: true }, component: () => import('../../views/hrm/attendance/attendance.vue') },
    { path: '/hrm/attendance/attendance-list', name: 'Attendance', meta: { title: 'Attendance', requiresAuth: true }, component: () => import('../../views/hrm/attendance/attendance.vue') },
    { path: '/hrm/attendance/roaster', name: 'Roaster', meta: { title: 'Roaster', requiresAuth: true }, component: () => import('../../views/hrm/attendance/roaster.vue') },
    { path: '/hrm/attendance/assign-shift', name: 'Assign-Shift', meta: { title: 'Assign Shift', requiresAuth: true }, component: () => import('../../views/hrm/attendance/assignshifts.vue') },
    { path: '/hrm/payroll/payroll-list', name: 'Payroll List', meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../../views/hrm/payroll/payroll.vue') },
    { path: '/hrm/payroll/payroll-configuration', name: 'Payroll Configuration', meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../../views/hrm/payroll/config.vue') },
    { path: '/hrm/employee/employee-accounts-settings', name: 'Employee Account Settings', meta: { title: 'Employee Account Setting', requiresAuth: true }, component: () => import('../../views/hrm/employee/accountssettings.vue') },

    { path: '/hrm/leave-managment/settings', name: 'Leave Managment', meta: { title: 'Leave Managment', requiresAuth: true }, component: () => import('../../views/hrm/leave/leave.vue') },


    { path: '/reports/hrm/work-location', name: 'work-location', meta: { title: 'work-location', requiresAuth: true }, component: () => import('../../views/hrm/work-location/work-location.vue') },
    { path: '/reports/hrm/iqama', name: 'iqama', meta: { title: 'iqama', requiresAuth: true }, component: () => import('../../views/hrm/iqama/iqama.vue') },
    { path: '/reports/hrm/work-permit', name: 'work-permit', meta: { title: 'work-permit', requiresAuth: true }, component: () => import('../../views/hrm/work-permit/work-permit.vue') },
    { path: '/reports/hrm/medical', name: 'medical', meta: { title: 'medical', requiresAuth: true }, component: () => import('../../views/hrm/medical/medical.vue') },
    { path: '/reports/hrm/document', name: 'document', meta: { title: 'document', requiresAuth: true }, component: () => import('../../views/hrm/documents/documents.vue') },
    { path: '/reports/hrm/education', name: 'education', meta: { title: 'education', requiresAuth: true }, component: () => import('../../views/hrm/education/education.vue') },
    { path: '/reports/hrm/passport', name: 'passport', meta: { title: 'passport', requiresAuth: true }, component: () => import('../../views/hrm/passport/passport.vue') },
    { path: '/reports/hrm/visa', name: 'visa', meta: { title: 'visa', requiresAuth: true }, component: () => import('../../views/hrm/visa/visa.vue') },
    { path: '/reports/hrm/driving-license', name: 'driving-license', meta: { title: 'driving-license', requiresAuth: true }, component: () => import('../../views/hrm/driving-license/driving-license.vue') },
    { path: '/reports/hrm/projects', name: 'project', meta: { title: 'project', requiresAuth: true }, component: () => import('../../views/hrm/project/project.vue') },
    { path: '/reports/hrm/assets', name: 'assets', meta: { title: 'assets', requiresAuth: true }, component: () => import('../../views/hrm/assets/assets.vue') },
    { path: '/reports/hrm/dependent', name: 'dependent', meta: { title: 'dependent', requiresAuth: true }, component: () => import('../../views/hrm/dependent/dependent.vue') },



]
