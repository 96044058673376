// import axios from "../common-axios";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_SERVER
class AuthService {

  async login(data: any) {
    return await axios.post("/auth/token/", data);
  }

  getauth() {
    return axios.get("/api/auth-user/");
  }
  async verifyToken(data: any) {
    return await axios.post('auth/token/verify', data)
  }
  refreshToken(data: any) {
    return axios.post('auth/token/refresh', data)
  }

  getAll() {
    return axios.get("/hrm/employee/");
  }

  get(id: string) {
    return axios.get(`/hrm/employee//${id}`);
  }

  create(data: any) {
    return axios.post("/hrm/employee/", data);
  }

  update(id: string, data: any) {
    return axios.put(`/hrm/employee//${id}`, data);
  }

  delete(id: string) {
    return axios.delete(`/hrm/employee//${id}`);
  }

  deleteAll() {
    return axios.delete(`/hrm/employee/`);
  }

  findByTitle(title: string) {
    return axios.get(`/hrm/employee/?title=${title}`);
  }
}

export default new AuthService();