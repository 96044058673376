import  {RouteConfig} from 'vue-router'
export const receivablespayablesRoutes: Array<RouteConfig> = [

  // RECEIVABLES AND PAYABLES
  { path: '/reports/receivables&payables/receivables', name: 'Receivables', component: () => import('../../views/reports/receivables_and_payables/receivables.vue') },
  { path: '/reports/receivables&payables/accountreceivables', name: 'AccountReceivables', component: () => import('../../views/reports/receivables_and_payables/accountreceivables.vue') },
  { path: '/reports/receivables&payables/payables', name: 'Payables', component: () => import('../../views/reports/receivables_and_payables/payables.vue') },
  { path: '/reports/receivables&payables/accountpayables', name: 'AccountPayables', component: () => import('../../views/reports/receivables_and_payables/accountpayables.vue') },
  { path: '/reports/receivables&payables/overduereceivables', name: 'OverdueReceivables', component: () => import('../../views/reports/receivables_and_payables/overduereceivables.vue') },
  { path: '/reports/receivables&payables/overduepayables', name: 'OverduePayables', component: () => import('../../views/reports/receivables_and_payables/overduepayables.vue') },
  { path: '/reports/receivables&payables/pendingbills-customers', name: 'PendingBillsForCustomers', component: () => import('../../views/reports/receivables_and_payables/pendingbillsforcustomers.vue') },
  { path: '/reports/receivables&payables/pendingbills-customer', name: 'PendingBillsForCustomer', component: () => import('../../views/reports/receivables_and_payables/pendingbillsforcustomer.vue') },
  { path: '/reports/receivables&payables/pendingbills-supplier', name: 'PendingBillsForSupplier', component: () => import('../../views/reports/receivables_and_payables/pendingbillsforsupplier.vue') },
  { path: '/reports/receivables&payables/pendingbills-suppliers', name: 'PendingBillsForSuppliers', component: () => import('../../views/reports/receivables_and_payables/pendingbillsforsuppliers.vue') },

]
