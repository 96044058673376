import { RouteConfig } from 'vue-router'
export const profitlossRoutes: Array<RouteConfig> = [
  // PROFIT & LOSS
  { path: '/reports/finance/profit-loss/profit-loss-create', name: 'ProfitLoss', component: () => import('../../views/reports/finance/profit-loss.vue') },

  // { path: '/reports/finance/profit-loss/profitloss', name: 'ProfitLoss', component: () => import('../../views/reports/finance/profit-loss.vue') },
  { path: '/reports/finance/profit-loss/profit-loss-segment', name: 'ProfitLossSegment', component: () => import('../../views/reports/finance/profitloss-segment.vue') },
  { path: '/reports/finance/profit-loss/profit-loss-previous-year', name: 'ProfitLossPreviousYear', component: () => import('../../views/reports/finance/profitloss-previousyear.vue') },
  { path: '/reports/finance/profit-loss/profit-loss-division', name: 'ProfitLossDivision', component: () => import('../../views/reports/finance/profitloss-division.vue') },
]
