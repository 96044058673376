// import axios from "../common-axios";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_SERVER

class UserManagement {
    get_Employee_by_Title(title: any) {
        return axios.get(`hrm/employee/?search=${title}`)
    }
    create_User(data: any) {
        return axios.post('api/create-user/', data)
    }
    update_User(id: number, data: any) {
        return axios.patch(`api/user/${id}/`, data)
    }
    deleteUser(id: number) {
        return axios.delete(`api/user/${id}`)
    }
    get_all_screens() {
        return axios.get("api/get-all-screens/")
    }
    create_screen(data: any) {
        return axios.post('api/screen/', data)
    }
    delete_screen(id: number) {
        return axios.delete(`api/screen/${id}`)
    }
    update_screen(id: number, data: any) {
        return axios.put(`api/screen/${id}/`, data)
    }
    post_screen_permission(data: any) {
        return axios.post('api/create_permission/', data)
    }
    update_screen_permission(id: number, data: any) {
        return axios.put(`api/permission/${id}/`, data)
    }
    delete_screen_permission(id: number) {
        return axios.delete(`api/permission/${id}`)
    }
    get_screen_permission() {
        return axios.get('api/permission/')
    }
    post_group_permissoin(data: any) {
        return axios.post('api/create-group/', data)
    }
    update_group_permission(id: number, data: any) {
        return axios.put(`api/group/${id}/`, data)
    }
    delete_group(id: number) {
        return axios.delete(`api/group/${id}`)
    }
    get_groups() {
        return axios.get('api/create-group/')
    }
    get_routes() {
        return axios.get('api/get-routes/');
    }
    get_components(id: any) {
        return axios.get(`api/get-screen-by-id/?id=${id}`);
    }
    post_user_permission(data: any) {
        return axios.post('api/create-user-profile/', data)
    }
    update_user_permission(id: number, data: any) {
        return axios.put(`api/userprofile/${id}/`, data)
    }
    delete_user_permission(id: number) {
        return axios.delete(`api/userprofile/${id}/`)
    }
    get_user() {
        // return axios.get('api/userprofile/')
        return axios.get('api/get-userprofile/');
    }
    getAll_user() {
        return axios.get('api/get-all-users/');
    }
    get_screen_modules() {
        return axios.get('api/get-screen-modules/')
    }
    post_screen_modules(data: any) {
        return axios.post('api/module/', data)
    }
    update_module(id: number, data: any) {
        return axios.put(`api/module/${id}/`, data)
    }
    delete_module(id: number) {
        return axios.delete(`api/module/${id}/`)
    }
    get_screen_module_by_id(id: number) {
        return axios.get(`api/get-module-by-screen-id/?id=${id}`)
    }
    logout() {
        return axios.post('api/logout/')
    }
    changePassword(data: any) {
        return axios.post('api/change-password/', data)
    }
    get_User_by_Title(title: any) {
        return axios.get(`api/get-all-users/?employee_title=${title}`)
    }
    forget_Password(data: any) {
        return axios.post(`api/password-reset/`, data)
    }
    resetPassword(data: any, middle: string, last: string) {
        return axios.post(`api/password-update/${middle}/${last}/`, data)
    }
    get_Login_User() {
        return axios.get('api/get-user-employee/');
    }
    getUserProfile() {
        return axios.get('api/user-profile/')
    }
}

export default new UserManagement();