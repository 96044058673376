import { Vue } from 'vue-property-decorator';

export class RecentActivites extends Vue {
  recentUrls: object[];
  max_length = 9;
  constructor() {
    super();
    this.recentUrls = [];
  }
  private reduceLength() {                              // max_limit reduce list
    if (this.recentUrls.length >= this.max_length) {
      let _reverse = this.recentUrls.reverse()
      _reverse = _reverse.slice(0, this.max_length + 1);
      _reverse = _reverse.reverse();
      this.recentUrls = _reverse;
    }
  }
  private getActivites(): object[] {
    const recentUrlsString = localStorage.getItem('recentUrls');
    let _recentUrls: object[] = [];
    if (recentUrlsString !== null) {
      _recentUrls = JSON.parse(recentUrlsString);
    }
    else if (recentUrlsString == null) {
      _recentUrls = []
    }
    return _recentUrls
  }

  public push(url: any, name: string) {
    this.recentUrls = this.getActivites();
    if (this.recentUrls.length > 0) {
      // dont replicate the route in drop down but route if it is not on window on restart the app
      let last_route: any = this.recentUrls[this.recentUrls.length - 1]
      if (last_route['url'] != url && last_route['name'] != name) {
        this.recentUrls.push({
          url: url,
          name: name
        })
      }
    }
    else {
      this.recentUrls.push({
        url: url,
        name: name
      })
    }
    this.reduceLength();
    localStorage.setItem('recentUrls', JSON.stringify(this.recentUrls));
  }

  public allActivites(): object[] {
    return this.getActivites()
  }
  public lastActivity(): string {   // curent open window not to navigate again
    let current_url_str = window.location.hash;
    let current_url_arr = current_url_str.split("#")
    return current_url_arr[1];
  }
}
export const recentActivites = new RecentActivites();