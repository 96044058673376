import  {RouteConfig} from 'vue-router'
export const balancesRoutes: Array<RouteConfig> = [
    // BALANCES
  { path: '/reports/balances/customerbalances', name: 'CustomerBalances', component: () => import('../../views/reports/balances/customerbalances.vue') },
  { path: '/reports/balances/supplierbalances', name: 'SupplierBalances', component: () => import('../../views/reports/balances/supplierbalances.vue') },
  { path: '/reports/balances/trialbalance-treeview', name: 'TrialBalanceTreeView', component: () => import('../../views/reports/balances/trialbalance-treeview.vue') },
  { path: '/reports/balances/accountbalances-treeview', name: 'AccountBalancesTreeView', component: () => import('../../views/reports/balances/accountbalances-treeview.vue') },

  { path: '/reports/balances/accountbalances/standardreport', name: 'StandardReport', component: () => import('../../views/reports/balances/accountbalances/standardreport.vue') },
  { path: '/reports/balances/accountbalances/subaccountsummary', name: 'SubAccountSummary', component: () => import('../../views/reports/balances/accountbalances/subaccountsummary.vue') },
]
