import { RouteConfig } from 'vue-router'
export const reportsRoutes: Array<RouteConfig> = [
  // Reports 
  { path: '/reports/hrm/dashboard', name: 'HRM-Dashboard', component: () => import('../../views/reports/hr/summary-reports.vue') },
  // { path: '/reports/finance', name: 'Reports', component: () => import('../views/reports/reports.vue') },
  { path: '/reports/finance/dashboard', name: 'Finance-Dashboard', component: () => import('../../views/reports/finance/finance-reports.vue') },
  { path: '/reports/sales/dashboard', name: 'Sales-Dashboard', meta: { title: 'Reports', requiresAuth: true }, component: () => import('../../views/reports/sales/project-invoices/sale-reports.vue') },
  { path: '/reports/sales/project-invoices/sale', name: 'Project-Sales-Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/reports/sales/project-invoices/invoices.vue') },
  { path: '/reports/sales/project-invoices/sales-credit-note', name: 'Project-Sales-Credit-Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/reports/sales/project-invoices/invoices-credit-note.vue') },

  //Customer Invioce
  { path: '/reports/sales/customer-invoices/customer', name: 'Customer-Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/reports/sales/customer-invoices/customer.vue') },

  //Cash Invoice
  { path: '/reports/sales/cash-invoices/cash', name: 'Cash-Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/reports/sales/cash-invoices/cash.vue') },

  //Internal Invoice
  { path: '/reports/sales/internal-invoices/internal', name: 'Internal-Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../../views/reports/sales/internal-invoice/internal.vue') },

]
