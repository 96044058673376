import { RouteConfig } from 'vue-router'
import Home from '../../views/Home.vue'


export const homeRoutes: Array<RouteConfig> = [
  //Home means single route
  // { path: '/', name: 'Home', component: Home, meta: { title: 'Home'} },
  { path: '/', name: 'Home', component: Home, meta: { title: 'Home', requiresAuth: true } },
]

