import { RouteConfig } from 'vue-router'
export const userRoutes: Array<RouteConfig> = [
    { path: '/user-management/user-creation', name: 'User-Creation', meta: { title: 'User-Creation', requiresAuth: true }, component: () => import('../../views/user-management/user-creation.vue') },
    { path: '/user-management/modules', name: 'Modules', meta: { title: 'Modules', requiresAuth: true }, component: () => import('../../views/user-management/modules.vue') },
    { path: '/user-management/screens', name: 'Screens', meta: { title: 'Screens', requiresAuth: true }, component: () => import('../../views/user-management/screens.vue') },
    { path: '/user-management/permissions', name: 'Permissions', meta: { title: 'Permissions', requiresAuth: true }, component: () => import('../../views/user-management/permissions.vue') },
    { path: '/user-management/groups', name: 'Groups', meta: { title: 'Groups', requiresAuth: true }, component: () => import('../../views/user-management/groups.vue') },
    { path: '/user-management/user-permissions', name: 'User-Permissions', meta: { title: 'User-Permissions', requiresAuth: true }, component: () => import('../../views/user-management/user-permissions.vue') },

]
