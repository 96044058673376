import  {RouteConfig} from 'vue-router'
export const taxesRoutes: Array<RouteConfig> = [
    // TAXES

  // vat-cs-tax-details
  { path: '/reports/taxes/vat-cst-tax-details/vat-cst-output', name: 'VatCstOutput', component: () => import('../../views/reports/taxes/vat-cs-tax-details/vat-cst-output.vue') },
  { path: '/reports/taxes/vat-cst-tax-details/vat-cst-input', name: 'VatCstInput', component: () => import('../../views/reports/taxes/vat-cs-tax-details/vat-cst-input.vue') },
  { path: '/reports/taxes/vat-cst-tax-details/tax-summary', name: 'TaxSummary', component: () => import('../../views/reports/taxes/vat-cs-tax-details/tax-summary.vue') },

  // gcc
  { path: '/reports/taxes/gcc/quarterlysales', name: 'QuarterlySales', component: () => import('../../views/reports/taxes/gcc/quarterly-sales.vue') },
  { path: '/reports/taxes/gcc/quarterlypurchase', name: 'QuarterlyPurchase', component: () => import('../../views/reports/taxes/gcc/quarterly-purchase.vue') },
  { path: '/reports/taxes/gcc/vatreturn', name: 'VatReturn', component: () => import('../../views/reports/taxes/gcc/vat-return.vue') },
  { path: '/reports/taxes/gcc/bahrainvat', name: 'BahrainVat', component: () => import('../../views/reports/taxes/gcc/bahrain-vat.vue') },
]
