import axios from "axios";
export const isHandled_AuthToken = (token: any) => {
  try {
    localStorage.setItem("jwt", token);
    set_authtoken_toHeader(token);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const set_authtoken_toHeader = (token: string) => {
  if (token) {
    axios.defaults.baseURL = process.env.VUE_APP_SERVER
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;

  } else delete axios.defaults.headers.common["Authorization"];
};