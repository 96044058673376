import  {RouteConfig} from 'vue-router'
export const projectRoutes: Array<RouteConfig> = [

    { path: '/projects/list', name: 'List', meta: { title: 'List', requiresAuth: true }, component: () => import('../../views/project/list/list.vue') },
    { path: '/project-create-list', name: 'project-create-list', props: true, meta: { title: 'project-create-list', requiresAuth: true }, component: () => import('../../views/project/list/create-list.vue') },
    { path: '/projects/order', name: 'order', meta: { title: 'Order', requiresAuth: true }, component: () => import('../../views/project/order/order.vue') },
    { path: '/create-order', name: 'Create-Order', meta: { title: 'Create Order', requiresAuth: true }, component: () => import('../../views/project/order/create-order.vue') },
    { path: '/projects/add-order-force', name: 'Add-Order-Force', meta: { title: 'Add-Order-Force', requiresAuth: true }, component: () => import('../../views/project/add-order-force/add-order-force.vue') },
    { path: '/create-order-force', name: 'Create-Order-Force', meta: { title: 'Create-Order-Force', requiresAuth: true }, component: () => import('../../views/project/add-order-force/create-order-force.vue') },
    
    // by default -> to list
]
