import { RouteConfig } from 'vue-router'
export const settingsRoutes: Array<RouteConfig> = [
  { path: '/user-management', name: 'user-management', meta: { title: 'User-Management', requiresAuth: true }, component: () => import('../../views/settings/user-management/user-management.vue') },
  { path: '/reset-password', name: 'reset-password', meta: { title: 'Reset-Password', requiresAuth: true }, component: () => import('../../views/settings/reset-password/reset-password.vue') },
  { path: '/administration', name: 'Administration', meta: { title: 'Administration', requiresAuth: true }, component: () => import('../../views/settings/administration/administration.vue') },
  { path: '/assets', name: 'Assets', meta: { title: 'Assets', requiresAuth: true }, component: () => import('../../views/settings/administration/assets/assets.vue') },
  { path: '/blood-group', name: 'blood-group', meta: { title: 'Blood-Group', requiresAuth: true }, component: () => import('../../views/settings/administration/blood-group/blood-group.vue') },
  { path: '/branch', name: 'branch', meta: { title: 'Branch', requiresAuth: true }, component: () => import('../../views/settings/administration/branch/branch.vue') },
  { path: '/department', name: 'department', meta: { title: 'Department', requiresAuth: true }, component: () => import('../../views/settings/administration/department/department.vue') },
  { path: '/designations', name: 'designations', meta: { title: 'Designations', requiresAuth: true }, component: () => import('../../views/settings/administration/designations/designations.vue') },
  { path: '/education-details', name: 'education-details', meta: { title: 'Education-Details', requiresAuth: true }, component: () => import('../../views/settings/administration/education-details/education-details.vue') },
  { path: '/employment-types', name: 'employment-types', meta: { title: 'Employment-Types', requiresAuth: true }, component: () => import('../../views/settings/administration/employment-types/employment-types.vue') },
  { path: '/expats', name: 'expats', meta: { title: 'Expats', requiresAuth: true }, component: () => import('../../views/settings/administration/expats/expats.vue') },
  { path: '/nationality', name: 'nationality', meta: { title: 'Nationality', requiresAuth: true }, component: () => import('../../views/settings/administration/nationality/nationality.vue') },
  { path: '/sub-branch', name: 'sub-branch', meta: { title: 'Sub-Branch', requiresAuth: true }, component: () => import('../../views/settings/administration/sub-branch/sub-branch.vue') },
  { path: '/import-admin', name: 'Import-admin', meta: { title: 'Import', requiresAuth: true }, component: () => import('../../views/settings/administration/import/import.vue') },
]
