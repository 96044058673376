import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import './assets/style.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker';
// @ts-ignore
import VueExcelXlsx from "vue-excel-xlsx";
// @ts-ignore
import HighchartsVue from 'highcharts-vue'
const moment = require('moment')
import { bookmark } from './utils/BookMark'
import { nav_data } from './assets/data'
import { Tafgeet } from "tafgeet-arabic";
// import {WorkflowChart} from 'vue-workflow-chart'
import 'vue-select/dist/vue-select.css';
import VueApexCharts from 'vue-apexcharts'


Vue.use(VueApexCharts)






Vue.use(BootstrapVue);
Vue.use(VueExcelXlsx)
Vue.use(BootstrapVueIcons)
Vue.use(require('vue-moment'), {
  moment
})
Vue.component('Tafgeet', Tafgeet)
Vue.component('v-select', vSelect)
Vue.component('date-picker', DatePicker)
Vue.component('apexchart', VueApexCharts)
Vue.use(HighchartsVue)

// If you try to use the <ion-icon name="add"></ion-icon> 
// element in your templates, it will properly render but 
// you will get the error in your console: so thats why i to register i am using this 
// ref link -> https://dev.to/devmount/how-to-use-ionicons-v5-with-vue-js-53g2
Vue.config.ignoredElements = [/^ion-/]






Vue.config.productionTip = false
//remove when book mark is added in db
bookmark.generateList(nav_data),
  new Vue({
    router,
    store,

    render: h => h(App)
  }).$mount('#app')

