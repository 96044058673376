import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authRoutes } from "./auth";
import { homeRoutes } from "./home";
import { hrmRoutes } from "./hrm";
import { assetsRoutes } from "./assets";
import {
  bankingRoutes,
  enquiresRoutes,
  financeRoutes,
  invoicesRoutes,
  journalRoutes,
  openingbalance_Routes,
  ordersRoutes,
  partyadjustmentsRoutes,
  paymentsRoutes,
  purchaseRoutes,
  quotationsRoutes,
  finance_receiptsRoutes,
  purchase_reciptsRoutes,
  returnRoutes,
  requisitionsRoutes,
  salesRoutes,
  vatcstRoute,
  productRoute,
  chartsofaccount_Routes,
  costcenter_Routes,
} from "./accounts";
import { vendorsRoutes } from "./vendor";
import { settingsRoutes } from "./settings";
import {
  balancesheetRoutes,
  balancesRoutes,
  daybookRoutes,
  inventoryRoutes,
  ledgerRoutes,
  opentransactionsRoutes,
  othersRoutes,
  profitlossRoutes,
  receivablespayablesRoutes,
  reportsRoutes,
  taxesRoutes,
  transactionslistingRoutes,
  trialbalanceRoutes,
  incomestatmentRoutes,
} from "./reports";
import { errorRoutes } from "./error";
import { projectRoutes } from "./project";
import { userRoutes } from "./user/user_routes";
import { isHandled_AuthToken } from "@/utils/handleAuthToken";
import authService from "@/services/auth-service";
Vue.use(VueRouter);

const baseRoutes: Array<RouteConfig> = [];
const routes: Array<RouteConfig> = baseRoutes.concat(
  authRoutes,
  homeRoutes,
  hrmRoutes,
  assetsRoutes,
  enquiresRoutes,
  bankingRoutes,
  costcenter_Routes,
  financeRoutes,
  invoicesRoutes,
  journalRoutes,
  productRoute,
  openingbalance_Routes,
  ordersRoutes,
  partyadjustmentsRoutes,
  paymentsRoutes,
  purchaseRoutes,
  quotationsRoutes,
  finance_receiptsRoutes,
  purchase_reciptsRoutes,
  returnRoutes,
  requisitionsRoutes,
  salesRoutes,
  vatcstRoute,
  vendorsRoutes,
  settingsRoutes,
  daybookRoutes,
  balancesRoutes,
  balancesheetRoutes,
  profitlossRoutes,
  inventoryRoutes,
  ledgerRoutes,
  opentransactionsRoutes,
  othersRoutes,
  receivablespayablesRoutes,
  reportsRoutes,
  taxesRoutes,
  transactionslistingRoutes,
  trialbalanceRoutes,
  projectRoutes,
  errorRoutes,
  chartsofaccount_Routes,
  userRoutes,
  incomestatmentRoutes
);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (
//     localStorage.getItem("jwt") === null ||
//     localStorage.getItem("jwt") === undefined ||
//     localStorage.getItem("jwt") === ""
//   ) {
//     if (to.path !== "/login" && to.path !== "/forget") {
//       next("/login");
//     } else {
//       if (isHandled_AuthToken(localStorage.getItem("jwt"))) {
//         next();
//       }
//     }
//   } else {
//     if (to.path == "/login") {
//       localStorage.removeItem("jwt");
//     }

//     if (isHandled_AuthToken(localStorage.getItem("jwt"))) {
//       next();
//     }
//   }
// });
router.beforeResolve(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const jwtToken = localStorage.getItem('jwt');
  const tokenObject = {
    token: jwtToken
    // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjk0NTg5MDc0LCJlbWFpbCI6ImFkbWluQGZ0LmNvbSJ9.lDGk7ecD5Lhao8darg1tZcqEI4xROQDgGKbHiqA_x54'
  }
  if (!jwtToken || jwtToken === '') {
    if (requiresAuth) {
      next('/login');
    }
    else {
      next();
    }
  }
  else if (to.path === '/login' && jwtToken) {
    window.location.reload();
    localStorage.removeItem('jwt');
    localStorage.removeItem('route');
    localStorage.removeItem('user');
    next('/login')
  }
  else {
    try {
      authService.verifyToken(tokenObject)
        .then((res) => {
          const isValidToken = isHandled_AuthToken(jwtToken);

          if (isValidToken) {
            const localRoutes: any = localStorage.getItem('route');
            const authorizedRoutes = JSON.parse(localRoutes);
            let parts = to.path.split('/')
            // console.log(parts)
            if (parts[1] === "invoice" && !isNaN(parseInt(parts[3]))) {
              // check invoice and this is only for project invoices 
              if (requiresAuth && (!authorizedRoutes || !authorizedRoutes.includes('/' + parts[1] + '/' + parts[2] + '/'))) {
                next('/403');
              } else {
                next();
              }
            }
            else if (parts[1] === 'payroll-slip' && !isNaN(parseInt(parts[2]))) {
              if (requiresAuth && (!authorizedRoutes || !authorizedRoutes.includes('/' + parts[1] + '/'))) {
                next('/403');
              } else {
                next();
              }
            }
            else if (parts[1] === 'product' && !isNaN(parseInt(parts[4]))) {
              if (requiresAuth && (!authorizedRoutes || !authorizedRoutes.includes('/' + parts[1] + '/' + parts[2] + '/' + parts[3] + '/'))) {
                next('/403');
              } else {
                next();
              }
            }
            else {
              if (requiresAuth && (!authorizedRoutes || !authorizedRoutes.includes(to.path))) {
                next('/403');
              } else {
                next();
              }
            }


          } else {
            localStorage.removeItem('jwt');
            localStorage.removeItem('route');
            localStorage.removeItem('user');
            next('/login');
          }
        }).catch((error) => {
          if (error.response.data.non_field_errors) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('route');
            localStorage.removeItem('user');
            next('/login')
          } else {
            next();
          }
        });
    } catch (error) {
      localStorage.removeItem('jwt');
      localStorage.removeItem('route');
      localStorage.removeItem('user');
      next('/login');
    }
  }
});


export default router;
