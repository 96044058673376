// import http from "../common-http";

// import fileAPI from "../common-http";
import axios from "axios";

class HRMService {
  getAll() {
    return axios.get("/hrm/employee/");
  }

  getOrders() {
    return axios.get("/sales/get-sale-order/0");
  }

  getOrderItems(id: number) {
    return axios.get(`/sales/get-sale-order/${id}`);
  }

  get_employee(id: string) {
    return axios.get(`hrm/get-employee/${id}`);
  }

  post_employee_file(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    // return fileAPI.post(`/hrm/post-file-employee/`, formData);
    return axios.post(`/hrm/post-file-employee/`, formData);

  }

  post_salary_file(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    // return fileAPI.post(`/hrm/post-file-employee-salary/`, formData);
    return axios.post(`/hrm/post-file-employee-salary/`, formData);
  }

  post_payroll_file(file: any, data: any) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('year', data.year);
    formData.append('month', data.month);
    // return fileAPI.post(`/hrm/post-file-payroll/`, formData);
    return axios.post(`/hrm/post-file-payroll/`, formData);

  }


  getproductitem() {
    return axios.get("/product/item/");
  }

  getproductStock() {
    return axios.get("/product/item-stock/");
  }


  getAll_salary() {
    return axios.get("/hrm/get-salary/");
  }

  get_salary(id: string) {
    return axios.get(`/hrm/employee_salary/${id}`,);
  }

  get_attendance_sheet() {
    return axios.get("/hrm/attendance_sheet/");
  }

  get_attendance_sheet_by(obj: any) {
    return axios.post("/hrm/get-attendance-sheet/", obj);
  }

  get_project_by_emp(id: any) {
    return axios.get(`/project/get-project-employee/?employee_id=${id}`);
  }

  get_employee_payroll(obj: any) {
    return axios.post("/hrm/employee-payroll/", obj);
  }

  post_employee_payroll(obj: any) {
    return axios.post("/hrm/employee-payroll-generate/", obj);
  }


  employee_payrollById(id: any) {
    return axios.get(`/hrm/employee_payroll/${id}`);
  }

  employee_payroll_by_date(id: any, data: any) {
    return axios.post(`/hrm/employee-payroll-by-date/${id}`, data);
  }

  employee_salary_statement(id: any, data: any) {
    return axios.post(`/hrm/employee-salary-statement/${id}`, data);
  }

  import_attendance(obj: any) {
    return axios.post("/hrm/import-attendance/", obj);
  }

  create_attendance(obj: any) {
    return axios.post("/hrm/create-attendance/", obj);
  }

  getAll_attendance() {
    return axios.get("/hrm/get-attendance/");
  }

  get_attendance(id: string) {
    return axios.get(`/hrm/attendance/${id}`);
  }

  getAllassest() {
    return axios.get("/asset/list/");
  }



  get_asset_by_emp(id: any) {
    return axios.get(`/hrm/employee_asset_by_emp/?employee_id=${id}`);
  }

  create_emp_asset(file: any, data: any) {
    let formData = new FormData();

    formData.append('employee', data.employee);
    if (file != null || file != undefined) {
      formData.append('document', file);
    }
    formData.append('asset', data.asset);
    formData.append('asset_title', data.asset_title);
    formData.append('comments', data.comments);
    formData.append('assigned_date', data.assigned_date);
    // return fileAPI.post(`/hrm/employee_asset/`, formData);    
    return axios.post(`/hrm/employee_asset/`, formData);

  }
  update_emp_asset(is_handover: boolean, id: number, data: any, file: any, handover_doc: any) {
    let formData = new FormData();
    formData.append('employee', data.employee);
    if (!is_handover) {
      if (file != null || file != undefined) {
        formData.append('document', file);
      }
    }
    else if (is_handover) {
      if (handover_doc != null || handover_doc != undefined) {
        formData.append('handover_doc', handover_doc);
      }
      formData.append('returned_date', data.handover_date)
      formData.append('handover_to', data.handover_to_id)
    }
    formData.append('asset', data.asset);
    formData.append('asset_title', data.asset_title);
    formData.append('comments', data.comments);
    formData.append('assigned_date', data.assigned_date);
    // return fileAPI.post(`/hrm/employee_asset/`, formData);    
    return axios.put(`/hrm/employee_asset/${id}/`, formData);
  }


  get(id: string) {
    return axios.get(`/hrm/employee/${id}`);
  }

  create(data: any) {
    return axios.post("/hrm/employee/", data);
  }

  create_basic_emp(data: any) {
    return axios.post("/hrm/post-basic-employee/", data);
  }

  update(id: number, data: any) {
    return axios.put(`/hrm/employee/${id}/`, data);
  }

  create_salary(data: any) {
    return axios.post(`/hrm/employee_salary/`, data);
  }

  update_salary(id: any, data: any) {
    return axios.put(`/hrm/employee_salary/${id}/`, data);
  }

  delete(id: string) {
    return axios.delete(`/hrm/employee//${id}`);
  }

  deleteAll() {
    return axios.delete(`/hrm/employee/`);
  }

  findByTitle(title: string) {
    return axios.get(`/hrm/employee/?title=${title}`);
  }

  create_edu(data: any) {
    return axios.post(`/hrm/employee_education/`, data);
  }

  update_edu(id: number, data: any) {
    return axios.put(`/hrm/employee_education/${id}/`, data);
  }

  get_edu_by_emp(id: string) {
    return axios.get(`/hrm/employee_education_by_emp/?employee_id=${id}`);
  }

  create_passport(data: any) {
    return axios.post(`/hrm/employee_passport/`, data);
  }

  update_passport(id: number, data: any) {
    return axios.put(`/hrm/employee_passport/${id}/`, data);
  }

  get_passport_by_emp(id: string) {
    return axios.get(`/hrm/employee_passport_by_emp/?employee_id=${id}`);
  }

  create_iqama(data: any) {
    return axios.post(`/hrm/employee_iqama/`, data);
  }

  update_iqama(id: number, data: any) {
    return axios.put(`/hrm/employee_iqama/${id}/`, data);
  }

  get_iqama_by_emp(id: string) {
    return axios.get(`/hrm/employee_iqama_by_emp/?employee_id=${id}`);
  }

  create_visa(data: any) {
    return axios.post(`/hrm/employee_visa/`, data);
  }

  update_visa(id: number, data: any) {
    return axios.put(`/hrm/employee_visa/${id}/`, data);
  }

  get_visa_by_emp(id: string) {
    return axios.get(`/hrm/employee_visa_by_emp/?employee_id=${id}`);
  }

  create_workpermit(data: any) {
    return axios.post(`/hrm/employee_workpermit/`, data);
  }

  update_workpermit(id: number, data: any) {
    return axios.put(`/hrm/employee_workpermit/${id}/`, data);
  }

  get_workpermit_by_emp(id: string) {
    return axios.get(`/hrm/employee_workpermit_by_emp/?employee_id=${id}`);
  }

  create_drivinglicense(data: any) {
    return axios.post(`/hrm/employee_drivinglicense/`, data);
  }

  update_drivinglicense(id: number, data: any) {
    return axios.put(`/hrm/employee_drivinglicense/${id}/`, data);
  }

  get_drivinglicense_by_emp(id: string) {
    return axios.get(`/hrm/employee_drivinglicense_by_emp/?employee_id=${id}`);
  }

  create_medicalinsurance(data: any) {
    return axios.post(`/hrm/employee_medical/`, data);
  }

  update_medicalinsurance(id: number, data: any) {
    return axios.put(`/hrm/employee_medical/${id}/`, data);
  }

  get_medicalinsurance_by_emp(id: string) {
    return axios.get(`/hrm/employee_medical_by_emp/?employee_id=${id}`);
  }

  create_contract(data: any) {
    return axios.post(`/hrm/employee_contract/`, data);
  }

  update_contract(id: number, data: any) {
    return axios.put(`/hrm/employee_contract/${id}/`, data);
  }

  get_contract_by_emp(id: string) {
    return axios.get(`/hrm/employee_contract_by_emp/?employee_id=${id}`);
  }

  create_aramco(data: any) {
    return axios.post(`/hrm/employee_aramco/`, data);
  }

  update_aramco(id: number, data: any) {
    return axios.put(`/hrm/employee_aramco/${id}/`, data);
  }

  get_aramco_by_emp(id: string) {
    return axios.get(`/hrm/employee_aramco_by_emp/?employee_id=${id}`);
  }


  create_dependent(data: any) {
    return axios.post(`/hrm/employee_dependent/`, data);
  }

  update_dependent(id: number, data: any) {
    return axios.put(`/hrm/employee_dependent/${id}/`, data);
  }

  get_dependent_by_emp(id: string) {
    return axios.get(`/hrm/employee_dependent_by_emp/?employee_id=${id}`);
  }

  create_employee_leave(data: any) {
    return axios.post(`/hrm/employee_leave/`, data);
  }

  update_employee_leave(id: number, data: any) {
    return axios.put(`/hrm/employee_leave/${id}/`, data);
  }

  get_employee_leave_by_emp(id: string) {
    return axios.get(`/hrm/employee_leave_by_emp/?employee_id=${id}`);
  }
  get_employee_leave_applications(searchSelected: string) {
    return axios.get(`/hrm/employee-leave-application/?status=${searchSelected}`);

  }


  // create_doc(data: any) {
  //   return axios.post(`/hrm/employee_doc/`, data);
  // }

  update_doc(id: number, data: any) {
    return axios.put(`/hrm/employee_doc/${id}/`, data);
  }

  get_doc_by_emp(id: string) {
    return axios.get(`/hrm/employee_doc_by_emp/?employee_id=${id}`);
  }

  create_doc(file: any, data: any) {
    let formData = new FormData();
    formData.append('employee', data.employee);
    formData.append('document', file);
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('comments', data.comments);
    // return fileAPI.post(`/hrm/employee_doc/`, formData);
    return axios.post(`/hrm/employee_doc/`, formData);

  }
  // Work Location
  getAll_project_id() {
    return axios.get("/hrm/employee_aramco/"); // change wants to implement
  }
  getAll_worklocation() {
    return axios.get("/project/employee-project/"); // change wants to implement
  }
  getAll_iqama() {
    return axios.get("/hrm/employee_iqama/");
  }
  getAll_workpermit() {
    return axios.get("/hrm/employee_workpermit/");
  }
  getAll_medical() {
    return axios.get("/hrm/employee_medical/");
  }
  getAll_documents() {
    return axios.get("/hrm/employee_doc/");
  }
  getAll_education() {
    return axios.get("/hrm/employee_education/");
  }
  getAll_passports() {
    return axios.get("/hrm/employee_passport/");
  }
  getAll_visa() {
    return axios.get("/hrm/employee_visa/");
  }
  getAll_drivinglicense() {
    return axios.get("/hrm/employee_drivinglicense/");
  }
  getAll_assets() {
    return axios.get("/hrm/employee_asset/");
  }
  getAll_dependent() {
    return axios.get("/hrm/employee_dependent/");
  }
  getAllStatistics(param: any) {
    if (param === "all") {
      return axios.get("hrm/get-hrm-statistics/");
    }
    else {
      return axios.get("hrm/get-hrm-statistics/", { params: param });
    }
  }
  getNotification() {
    return axios.get("hrm/get-logs-notification/");
  }
  seenNotification(id: string) {
    return axios.put(`hrm/seen-logs-notification/?id=${id}`);
  }
  getPayrollLogs(month: any, year: any) {
    return axios.get(`hrm/get-bulk-uploads-for-payroll/?month=${month}&year=${year}`)
  }
  getPayrollConfig() {
    return axios.get('hrm/payroll_config/')
  }
  savePayrollConfig(payload: any) {
    return axios.post(`hrm/payroll_config/`, payload)
  }

  // Leave Management
  createLeaveType(payload: any) {
    return axios.post('hrm/leave_type/', payload)
  }
  updateLeaveType(payload: any, id: number) {
    return axios.put(`hrm/leave_type/${id}/`, payload)
  }
  getLeaveType() {
    return axios.get('hrm/leave_type/')
  }
  getLeaveTypeByTitle(title: string) {
    return axios.get(`hrm/leave_type/?title = ${title}`)
  }
  createEntitlementPolicy(payload: any) {
    return axios.post('hrm/entitlement-policy/', payload)
  }
  getEntitlementPolicy() {
    return axios.get('hrm/entitlement-policy/')
  }
  getEntitlementPolicyByTitle(title: string) {
    return axios.get(`hrm/entitlement-policy/?title=${title}`)
  }
  updateEntitlementPolicy(payload: any, id: number) {
    return axios.put(`hrm/entitlement-policy/${id}/`, payload)
  }
  createWorkingDays(payload: any) {
    return axios.post('hrm/shift/', payload)
  }
  updateWorkingDays(payload: any, id: number) {
    return axios.put(`hrm/shift/${id}/`, payload)
  }
  getWorkingDays() {
    return axios.get('hrm/shift/')
  }
  getWorkingDaysByTitle(title: any) {
    return axios.get(`hrm/shift/?search=${title}`)
  }
  createRoasterByProject(payload: any, assignment_type: string) {
    return axios.post(`hrm/assign-roaster/${assignment_type}/`, payload)
  }
  getRoasterData(assignment_type: string){
    return axios.get(`hrm/get-roaster-data/${assignment_type}/`)
  }
  createRoasterByEmployee(payload: any) {
    return axios.post(`hrm/roaster/`, payload)
  }
  getRoaster() {
    return axios.get(`hrm/roaster/`)
  }
  updateRoaster(payload: any, id: number) {
    return axios.put(`hrm/roaster/${id}/`, payload)
  }
  getEmployeeWiseLeave(employee: number) {
    return axios.get(`hrm/employee-wise-leaves/?employee=${employee}`)
  }
  getLeaveBalances(employee: string) {
    return axios.get(`hrm/employee-leave-balance/?employee=${employee}`)
  }
  handleLeaveApplications(data: any, type: string) {
    return axios.post(`hrm/handle-leave-applications/?request_type=${type}`, data)
  }
  updateLeaveApplications(data: any, type: string, id: number) {
    return axios.put(`hrm/handle-leave-applications/?request_type=${type}&id=${id}/`, data)
  }
  createGazzetedHolidays(file: any, holidays: any) {
    let formData = new FormData();
    formData.append('holidays', holidays);
    if (file !== null) {
      formData.append('attachments', file);
    }
    return axios.post(`/hrm/gazzeted_holidays/`, formData);
  }
  getLeaveHolidaysPlanner() {
    return axios.get('hrm/leave-holidays-planner/');
  }
  uploadBulkRoaster(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post('hrm/upload-bulk-roaster/', formData)
  }
  assignEmployeeLeaveEntitlement(data: any) {
    return axios.post('hrm/employee_leave_entitlement/', data)
  }
  updateEmployeeLeaveEntitlement(id: any, data: any) {
    return axios.put(`hrm/employee_leave_entitlement/${id}/`, data)
  }
  getEmployeeLeaveEntitlement(id: any) {
    return axios.get(`hrm/employee_leave_entitlement/?employee_id=${id}`)
  }
  removeEmployeeLeaveEntitlement(id: any) {
    return axios.delete(`hrm/employee_leave_entitlement/${id}/`)
  }
  getEmployeeLeave(id: string) {
    return axios.get(`hrm/get-leaves-by-employee/${id}/`)
  }
  getSeparationVerification(id:number){
    return axios.get(`hrm/get-sepration-verification/${id}/`)
  }
}

export default new HRMService();