import { RouteConfig } from 'vue-router'
export const productRoute: Array<RouteConfig> = [
  // product
  { path: '/ware-house/item-master', name: 'Item-Master', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/list/list.vue') },

  { path: '/ware-house/ware-house-recieve', name: 'Ware-House-Recieve', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list.vue') },
  { path: '/ware-house/ware-house-transfer', name: 'Ware-House-Transfer', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list-out.vue') },

  //{ path: '/product/stock/list', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list.vue') },
  { path: '/ware-house/stock-listing/inventory', name: 'Inventory', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/stock-list.vue') },
  { path: '/ware-house/stock-listing/consumable', name: 'Consumable', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/consumable.vue') },
  { path: '/ware-house/stock-listing/third-party', name: 'Third-Party', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/thirdparty.vue') },

  //   { path: '/product/stock/stock-list', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/stock-list.vue') },
  { path: '/product/stock/list-in-print/:id', name: 'List-In-Print', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list-in-print.vue') },
  { path: '/product/stock/list-out', name: 'List-Out', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list-out.vue') },
  { path: '/product/stock/list-out-print/:id', name: 'List-Out-Print', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/stock/list-out-print.vue') },
  { path: '/product/master/index', name: 'Master-Index', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/master/index.vue') },
  { path: '/ware-house/master/category', name: 'Master-Category', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/master/category.vue') },
  { path: '/ware-house/master/category-level-1', name: 'Master-Category-Level-1', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/master/category-level-1.vue') },
  { path: '/ware-house/master/category-level-2', name: 'Master-Category-Level-2', meta: { title: 'Product', requiresAuth: true }, component: () => import('../../views/product/master/category-level-2.vue') },

]
