import  {RouteConfig} from 'vue-router'
export const transactionslistingRoutes: Array<RouteConfig> = [
    // TRANSACTION-LISTING
  { path: '/reports/transactionslisting/complexjv-report', name: 'ComplexJvReport', component: () => import('../../views/reports/transaction-listing/journal-entries-complex/complex-jv-report.vue') },
  { path: '/reports/transactionslisting/cashreceipts', name: 'Cash Receipts', component: () => import('../../views/reports/transaction-listing/cashreceipts.vue') },
  { path: '/reports/transactionslisting/bankreceipts', name: 'Bank Receipts', component: () => import('../../views/reports/transaction-listing/bankreceipts.vue') },
  { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../../views/reports/transaction-listing/cashpayments.vue') },
  { path: '/reports/transactionslisting/bankpayments', name: 'Bank Payments', component: () => import('../../views/reports/transaction-listing/bankpayments.vue') },
  // { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../views/reports/transaction-listing/cashpayments.vue') },
  // { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../views/reports/transaction-listing/cashpayments.vue') },
]
