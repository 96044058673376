export const nav_data = [
    // HRM
    {
        name: "HRM",
        url: "/hrm",
        child: [
            {
                name: "Employee",
                url: "/employee",
                child: [
                    {
                        name: "Employee List",
                        url: "/employee-list",
                    },
                    {
                        name: "Salary",
                        url: "/salary",
                    },
                    {
                        name: "Account Settings",
                        url: "/employee-accounts-settings"
                    }
                ]
            },
            {
                name: "Attendance",
                url: "/attendance",
                child: [
                    {
                        name: "Attendance List",
                        url: "/attendance-list"
                    },
                    {
                        name: "Roaster",
                        url: "/roaster"
                    },
                    {
                        name: "Assign Shift",
                        url: "/assign-shift"
                    },
                ]
            },
            {
                name: "Payroll",
                url: "/payroll",
                child: [
                    {
                        name: "Payroll List",
                        url: "/payroll-list"
                    },
                    {
                        name: "Payroll Configuration",
                        url: "/payroll-configuration"
                    }
                ]
            },
            {
                name: "Leave Managment",
                url: "/leave-managment",
                child: [
                    {
                        name: "Settings",
                        url: "/settings"
                    }
                ]
            },

        ]
    },
    // Accounts
    {
        name: "Accounts",
        url: "/accounts",
        child: [
            //Finance

            {
                name: "Finance",
                url: "/finance",
                child: [
                    {
                        name: "Journals",
                        url: "/journals",
                        child: [
                            {
                                name: "Journal Entries",
                                url: "/journal-entries",

                            },
                            {
                                name: "Journal Entries [Complex]",
                                url: "/journal-entries-complex",
                            },
                            {
                                name: "Book Incomes or Receivable",
                                url: "/book-incomes-or-receivable",
                            },
                            {
                                name: "Book Expense or Payable",
                                url: "/book-expense-or-payable",
                            },

                        ]
                    },
                    {
                        name: "Receipts",
                        url: "/receipts",
                        disable: true,
                        child: [
                            {
                                name: "Cash Receipts",
                                url: "/cash-receipts"
                            },
                            {
                                name: "Bank Receipts",
                                url: "/bank-receipts",
                            },
                            {
                                name: "Receipts From Parties",
                                url: "/receipts-from-parties",
                            },
                        ]
                    },
                    {

                        name: "Payment",

                        url: "/payments",
                        disable: true,

                        child: [
                            {
                                name: "Cash Payments",
                                url: "/cash-payments",
                            },
                            {
                                name: "Bank Payments",
                                url: "/bank-payments",
                            },
                            {
                                name: "Cash Transfer",
                                url: "/cash-transfer",
                            },

                        ]
                    },
                    {
                        name: "Banking",
                        url: "/banking",
                        disable: true,
                        child: [
                            {
                                name: "Cash Deposit & Withdrawals",
                                url: "/cash-deposit&withdrawals",
                            },
                            {
                                name: "Inter Bank Fund Transfer",
                                url: "/inter-bank-funds-transfer",
                            },
                            {
                                name: "Bank Reconcilation",
                                url: "/bank-reconciliation",
                            },
                            {
                                name: "Issued Cheques Bounce",
                                url: "/issued-cheques-bounce",
                            },
                            {
                                name: "Received Cheques Bounce",
                                url: "/received-cheques-bounce",
                            },
                        ]
                    },
                    {
                        name: "Party Adjustment",
                        url: "/party-adjustments",
                        disable: true,
                        child: [
                            {
                                name: "Debit Notes",
                                url: "/debit-notes",
                            },
                            {
                                name: "Credit Notes",
                                url: "/credit-notes",
                            },
                            {
                                name: "Adjust Party Bills",
                                url: "/adjust-party-bills",
                            },

                        ]
                    },
                    {
                        name: "Opening Balances",
                        url: "/opening-balances",
                        child: [
                            {
                                name: "Opening Balances",
                                url: "/opening-balances",
                            },
                            // {
                            //     name: "Party Opening Balances",
                            //     url: "/party-opening-balances",
                            // },
                        ]
                    },
                    {
                        name: "VATCST",
                        url: "/vatcst",
                        disable: true,
                        child: [
                            {
                                name: "VAT Opening",
                                url: "/vat-openings",
                            },
                            {
                                name: "VAT Payments",
                                url: "/vat-payments",
                            },
                            {
                                name: "VAT Month End",
                                url: "/vat-month-end",
                            },
                        ]
                    },
                ]
            },

            // Purchase
            {
                name: "Purchase",
                url: "/purchase",
                child: [
                    {
                        name: "Requisitions",
                        url: "/requisitions",
                        child: [
                            {
                                name: "Purchase Requisitions List",
                                url: "/purchase-requisitions-list"
                            },
                            {
                                name: "Purchase Requisitions",
                                url: "/purchase-requisitions"
                            },
                            {
                                name: "Purchase Requisitions Cancellations",
                                url: "/purchase-requisitions-cancellations"
                            },
                        ]
                    },
                    {
                        name: "Enquiries",
                        url: "/enquiries",
                        child: [
                            {
                                name: "Purchase Enquiries List",
                                url: "/purchase-enquiries-list"
                            },
                            {
                                name: "Purchase Enquiries",
                                url: "/purchase-enquiries"
                            },
                            {
                                name: "Purchase Enquiries Cancellations",
                                url: "/purchase-enquiries-cancellations"
                            },
                            {
                                name: "Purchase Enquiries Against Requisitions",
                                url: "/purchase-enquiries-against-requisitions"
                            },
                        ]

                    },
                    {
                        name: "Quotations",
                        url: "/quotations",
                        child: [
                            {
                                name: "Purchase Quotations List",
                                url: "/purchase-quotations-list"
                            },
                            {
                                name: "Purchase Quotations",
                                url: "/purchase-quotations"
                            },
                            {
                                name: "Purchase Quotations Cancellations",
                                url: "/purchase-quotations-cancellations"
                            },
                            {
                                name: "Purchase Quotations Against Enquiries",
                                url: "/purchase-quotations-against-enquiries"
                            },
                        ]

                    },
                    {
                        name: "Orders",
                        url: "/orders",
                        child: [
                            {
                                name: "Purchase Orders List",
                                url: "/purchase-orders-list"
                            },
                            {
                                name: "Purchase Orders",
                                url: "/purchase-orders"
                            },
                            {
                                name: "Purchase Orders Againsts Requisitions",
                                url: "/purchase-orders-against-requisitions"
                            },
                            {
                                name: "Purchase Orders Againsts Quotations",
                                url: "/purchase-orders-against-quotations"
                            },
                            {
                                name: "Purchase Order Cancellation",
                                url: "/purchase-order-cancellation"
                            },
                        ]

                    },
                    {
                        name: "Receipts",
                        url: "/receipts",
                        child: [
                            {
                                name: "Material Receipts List",
                                url: "/material-receipts-list"
                            },
                            {
                                name: "Material Receipts",
                                url: "/material-receipts"
                            },
                            {
                                name: "Material Receipts Against Orders",
                                url: "/material-receipts-against-orders"
                            },
                            // {
                            //     name: "Material Receipts Cancellation",
                            //     url: "/material-receipts-cancellation"
                            // },
                        ]
                    },
                    {
                        name: "Invoices",
                        url: "/invoices",
                        child: [
                            {
                                name: "Purchase Voucher List",
                                url: "/purchase-voucher-list",
                            },
                            {
                                name: "Purchase Voucher",
                                url: "/purchase-voucher",
                            },
                            {
                                name: "Cash Purchase Voucher",
                                url: "/cash-purchase-voucher",
                            },
                            // {
                            //     name: "Purchase Voucher Cancellation",
                            //     url: "/purchase-voucher-cancellation",
                            // },
                            {
                                name: "Purchase Voucher Against Orders",
                                url: "/purchase-voucher-against-orders",
                            },
                            {
                                name: "Purchase Voucher Against Receipts",
                                url: "/purchase-voucher-against-receipts",
                            },
                        ]
                    },
                    {
                        name: "Return",
                        url: "/return",
                        child: [
                            {
                                name: "Material Return List",
                                url: "/material-return-list"
                            },
                            {
                                name: "Material Return",
                                url: "/material-return"
                            },
                            // {
                            //     name: "Purchase Return",
                            //     url: "/purchase-return"
                            // },
                            {
                                name: "Purchase Return With Invoice Reference",
                                url: "/purchase-return-with-invoice-reference"
                            }
                        ]
                    },
                    {
                        name:"Transaction Config",
                        url:"/transaction-configuration"
                        
                    }
                ]
            },

            // Sales
            {
                name: "Sales",
                url: "/sales",
                child: [
                    {
                        name: "Project Invoice",
                        url: "/invoice",
                        child: [
                            {
                                name: "Create Invoice",
                                url: "/generate-sale-invoice"
                            },
                            {
                                name: "Credit Note",
                                url: "/generate-sale-invoice-credit-note",
                            },
                            {
                                name: "General Credit Note",
                                url: "/generate-sale-invoice-general-credit-note",
                            }
                        ]
                    },
                    {
                        name: "Customer Invoice",
                        url: "/customer-invoice",
                        child: [
                            {
                                name: "Create Invoice",
                                url: "/generate-customer-sale-invoice"
                            },
                        ]
                    },
                    {
                        name: "Cash Invoice",
                        url: "/cash-invoice",
                        child: [
                            {
                                name: "Create Invoice",
                                url: "/generate-cash-sale-invoice"
                            },
                        ]
                    },
                    {
                        name: "Internal Invoice",
                        url: "/internal-invoice",
                        child: [
                            {
                                name: "Create Invoice",
                                url: "/generate-internal-invoice.vue"
                            },

                        ]
                    },

                ]
            },
            // Charts of Accounts
            {
                name: "Charts of Accounts",
                url: "/charts-of-account"
            },
            {
                name: "Cost Center Support",
                url: "/cost-center-support"
            },
            {
                name: "Business Partner",
                url: "/vendor/list"
            },
        ]
    },
    // Projects
    {
        name: "Projects",
        url: "/projects",
        child: [
            {
                name: "List",
                url: "/list",

            },
            {
                name: "Order",
                url: "/order",

            },
            // {
            //     name:"Add Order Force",
            //     url:"/add-order-force",

            // },
        ]
    },
    // Inventory
    {
        name: "Ware House",
        url: "/ware-house",
        child: [
            {
                name: "Stock Listing",
                url: "/stock-listing",
                child: [
                    {
                        name: "Inventory",
                        url: "/inventory",
                    },
                    {
                        name: "Consumable",
                        url: "/consumable",
                    },
                    {
                        name: "Third Party",
                        url: "/third-party",
                    },
                ]

            },
            {
                name: "Ware House/Stock (Recieve)",
                url: "/ware-house-recieve",

            },
            {
                name: "Ware House/Stock (Transfer)",
                url: "/ware-house-transfer",

            },
            {
                name: "Item Master",
                url: "/item-master",

            },
            {
                name: "Master",
                url: "/master",
                child: [
                    {
                        name: "Category",
                        url: "/category",
                    },
                    {
                        name: "Category Level 1",
                        url: "/category-level-1",
                    },
                    {
                        name: "Category Level 2",
                        url: "/category-level-2",
                    },
                ]
            },

        ]
    },
    // Assets
    {
        name: "Assets",
        url: "/assets",
        child: [
            {
                name:"Asset Class",
                url:"/asset-class"
            },
            {
                name: "Asset List",
                url: "/asset-list"
            },
            
        ]
    },
    // Reports
    {
        name: "Reports",
        url: "/reports",
        child: [
            {
                name: "Finance",
                url: "/finance",
                child: [
                    {
                        name: "Dashboard",
                        url: "/dashboard",
                    },
                    // {
                    //     name: "Cash Flow",
                    //     url: "/cash-flow",
                    // },
                    // {
                    //     name: "Funds Flow",
                    //     url: "/funds-flow",
                    // },
                    // {
                    //     name: "Profit & Loss",
                    //     url: "/profit-loss",
                    //     child: [
                    //         {
                    //             name: "Profit & Loss",
                    //             url: "/profit-loss-create",
                    //         },
                    //         {
                    //             name: "Profit & Loss [Segment]",
                    //             url: "/profit-loss-segment",
                    //         },
                    //         {
                    //             name: "Profit & Loss [Previous Year]",
                    //             url: "/profit-loss-previous-year",
                    //         },
                    //         {
                    //             name: "Profit & Loss [Division]",
                    //             url: "/profit-loss-division",
                    //         },
                    //     ]
                    // },
                    {
                        name: "Income Statement",
                        url: "/income-statement",
                        child: [
                            {
                                name: "Income Statement",
                                url: "/income-statement",
                            },
                        ]
                    },
                    {
                        name: "Balance Sheet",
                        url: "/balance-sheet",
                        child: [
                            {
                                name: "Balance Sheet",
                                url: "/balance-sheet",
                            },
                            {
                                name: "Trial Balance",
                                url: "/trial-balance",
                            },
                            // {
                            //     name: "Balance Sheet [Segment]",
                            //     url: "/balance-sheet-segment",
                            // },
                            // {
                            //     name: "Balance Sheet [Division]",
                            //     url: "/balance-sheet-division",
                            // },
                        ]
                    },
                    {
                        name: "Ledger",
                        url: "/ledger",
                        child: [
                            // {
                            //     name: "Standard Report",
                            //     url: "/standard-report"
                            // },
                            // {
                            //     name: "Data VAT",
                            //     url: "/data-vat"
                            // },
                            {
                                name: "Statements Of Accounts",
                                url: "/statements-of-accounts"
                            },
                            // {
                            //     name: "Ledger By Account Group",
                            //     url: "/ledger-by-account-group",
                            // },
                            // {
                            //     name: "Ledger With Bill Details",
                            //     url: "/ledger-with-bill-details",
                            // },
                            // {
                            //     name: "Ledger [Single Currency]",
                            //     url: "/ledger-single-currency",
                            // },
                            // {
                            //     name: "Ledger [Multi Currency]",
                            //     url: "/ledger-multi-currency",
                            // },
                            // {
                            //     name: "Ledger Details [2]",
                            //     url: "/ledger-details-2",
                            // },
                        ]
                    },

                    // {
                    //     name: "Books",
                    //     url: "/books",
                    //     child: [
                    //         {
                    //             name: "Day Book",
                    //             url: "/day-book",
                    //         },
                    //         {
                    //             name: "BRS",
                    //             url: "/brs",
                    //         },
                    //         {
                    //             name: "Bank Reconciliation",
                    //             url: "/bank-reconciliation",
                    //         },
                    //         {
                    //             name: "Bank Reconciliation [Multi Currency]",
                    //             url: "/bank-reconciliation-multi-currency",
                    //         },
                    //     ]
                    // },
                    // {
                    //     name: "Receivables & Payables",
                    //     url: "/receivables-payables",
                    //     child: [
                    //         {
                    //             name: "Receivables",
                    //             url: "/receivables",
                    //         },
                    //         {
                    //             name: "Accounts Receivables",
                    //             url: "/accounts-receivables",
                    //         },
                    //         {
                    //             name: "Payables",
                    //             url: "/payables",
                    //         },
                    //         {
                    //             name: "Accounts Payables",
                    //             url: "/accounts-payables",
                    //         },
                    //         {
                    //             name: "Overdue Receivables",
                    //             url: "/overdue-receivables",
                    //         },
                    //         {
                    //             name: "Overdue Payables",
                    //             url: "/overdue-payables",
                    //         },
                    //         {
                    //             name: "Pending Bills For Customer",
                    //             url: "/pending-bills-for-customer",
                    //         },
                    //         {
                    //             name: "Pending Bills For Customers",
                    //             url: "/pending-bills-for-customers",
                    //         },
                    //         {
                    //             name: "Pending Bills For Supplier",
                    //             url: "/pending-bills-for-supplier",
                    //         },
                    //         {
                    //             name: "Pending Bills For Suppliers",
                    //             url: "/pending-bills-for-suppliers",
                    //         },
                    //     ]
                    // },
                    // {
                    //     name: "Balances",
                    //     url: "/balances",
                    //     child: [
                    //         {
                    //             name: "Customer Balances",
                    //             url: "/customer-balances",
                    //         },
                    //         {
                    //             name: "Supplier Balances",
                    //             url: "/supplier-balances",
                    //         },
                    //         {
                    //             name: "Account Balances",
                    //             url: "/account-balances",
                    //             child: [
                    //                 {
                    //                     name: "Standard Report",
                    //                     url: "/standard-report",
                    //                 },
                    //                 {
                    //                     name: "Sub Account Summary",
                    //                     url: "/sub-account-summary",
                    //                 }
                    //             ]
                    //         },
                    //         {
                    //             name: "Trial Balances [Tree View]",
                    //             url: "/trial-balances-tree-view",
                    //         },
                    //         {
                    //             name: "Account Balances [Tree View]",
                    //             url: "/account-balances-tree-view",
                    //         },
                    //     ]
                    // },
                    // {
                    //     name: "Open Transactions",
                    //     url: "/open-transactions",
                    //     child: [
                    //         {
                    //             name: "Pending Booking Of Other Cost",
                    //             url: "/pendind-booking-of-other-cost",
                    //         },
                    //         {
                    //             name: "Pending Booking Of Other Cost [Import]",
                    //             url: "/pendind-booking-of-other-cost-import",
                    //         }
                    //     ]
                    // },
                ]
            },
            {
                name: "Sales",
                url: "/sales",
                child: [
                    {
                        name: "Dashboard",
                        url: "/dashboard",
                    },

                    {
                        name: "Project Invoices",
                        url: "/project-invoices",
                        child: [
                            {
                                name: "invoice",
                                url: "/sale"
                            },
                            {
                                name: "Invoice (Credit Note)",
                                url: "/sales-credit-note"
                            }
                        ]
                    },
                    {
                        name: "Customer Invoice",
                        url: "/customer-invoices",
                        child: [
                            {
                                name: "Invoice",
                                url: "/customer",
                            },
                        ]
                    },
                    {
                        name: "Cash Invoice",
                        url: "/cash-invoices",
                        child: [
                            {
                                name: "Invoice",
                                url: "/cash",
                            },
                        ]
                    },
                    {
                        name: "Internal Invoice",
                        url: "/internal-invoices",
                        child: [
                            {
                                name: "Invoice",
                                url: "/internal",
                            },
                        ]
                    },
                ]
            },
            {
                name: "HRM",
                url: "/hrm",
                child: [
                    {
                        name: "Dashboard",
                        url: "/dashboard",
                    },
                    {
                        name: "Education",
                        url: "/education"
                    },
                    {
                        name: "Passport",
                        url: "/passport"
                    },
                    {
                        name: "Iqama",
                        url: "/iqama"
                    },
                    {
                        name: "Projects",
                        url: "/projects",
                    },
                    {
                        name: "Visa",
                        url: "/visa"
                    },
                    {
                        name: "Work Permit",
                        url: "/work-permit"
                    },
                    {
                        name: "Driving License",
                        url: "/driving-license"
                    },
                    {
                        name: "Work Location",
                        url: "/work-location"
                    },
                    {
                        name: "Medical",
                        url: "/medical"
                    },
                    {
                        name: "Assets",
                        url: "/assets"
                    },
                    {
                        name: "Leave",
                        url: "/leave"
                    },
                    {
                        name: "Document",
                        url: "/document"
                    },
                    {
                        name: "Dependent",
                        url: "/dependent"
                    }
                ]
            },
            {
                name: "Stock",
                url: "/stock",
                child: [
                    {
                        name: "Stock Receive",
                        url: "/in",
                    },
                    {
                        name: "Stock Exit",
                        url: "/out",
                    },
                ]
            }

        ]
    },
    //User Management
    {
        name: "Settings",
        url: "/user-management",
        child: [
            // {
            //     name: "Modules",
            //     url: "/modules",
            // },
            // {
            //     name: "Screens",
            //     url: "/screens",
            // },
            {
                name: "User List",
                url: "/user-creation",
            },
            {
                name: "Permissions",
                url: "/permissions",
            },
            {
                name: "Group",
                url: "/groups",
            },
            {
                name: "User Permissions",
                url: "/user-permissions",
            },
        ]
    }



]

export const settings_menue = [
    // {
    //     name: "User Management",
    //     url: "/user-management",
    // },

];

export const user_menue = [
    {
        name: "profile",
        url: "/accounts",
    },
    {
        name: 'Reset Password',
        url: '/reset-password'
    },
    {
        name: "Administration",
        url: "/administration",
    },
    {
        name: "Logout",
        url: "/login",
    },
];