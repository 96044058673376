import { RouteConfig } from 'vue-router'
export const ledgerRoutes: Array<RouteConfig> = [
  // LEDGER
  { path: '/reports/finance/ledger', name: 'Ledger', component: () => import('../../views/reports/ledger/standardreport.vue') },
  { path: '/reports/finance/ledger/statements-of-accounts', name: 'StatementsOfAccounts', component: () => import('../../views/reports/ledger/statementsofaccounts.vue') },
  { path: '/reports/finance/ledger/statements-of-account-print', name: 'StatementsOfAccountsPrint', component: () => import('../../views/reports/ledger/statementsofaccounts-print.vue') },
  { path: '/reports/finance/ledger/data-vat', name: 'DataVat', component: () => import('../../views/reports/ledger/datavat.vue') },
  // /ledger/statements-of-accounts
  { path: '/reports/finance/ledger/ledger-by-account-group', name: 'LedgerByAccountGroup', component: () => import('../../views/reports/ledger-by-account-group.vue') },
  { path: '/reports/finance/ledger/ledger-with-bill-details', name: 'LedgerWithBillDetails', component: () => import('../../views/reports/ledger-with-bill-details.vue') },
  { path: '/reports/finance/ledger/ledger-singlecurrency', name: 'LedgerSingleCurrency', component: () => import('../../views/reports/ledger-singlecurrency.vue') },
  { path: '/reports/finance/ledger/ledger-multicurrency', name: 'LedgerMultiCurrency', component: () => import('../../views/reports/ledger-multicurrency.vue') },
  { path: '/reports/finance/ledger/ledger-detailed2', name: 'LedgerDetailed2', component: () => import('../../views/reports/ledger-detailed2.vue') },
]
